import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty, Toast, Dialog, Loading, Popup, Collapse, CollapseItem } from 'vant'
import { setTimeout } from 'core-js'

Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Toast)
export default {
  components: {},
  data () {
    return {
      name: ''
    }
  },
  created () {
    this.name = this.$route.query.name
  },

  methods: {

    back () {

      this.$router.go(-1)
    },
    toNum () {

    },
    toCancel () {
      this.$router.go(-1)
    }
  },
}
